<template>
  <div class="container">
    <h1 class="title">PEGATINA TECH LIMITED</h1>
    <h2 class="des">
      We are dedicated to delivering fun and innovative products
      that immerse users in relaxing, joyful, and engaging experiences.
    </h2>
    <div class="addr">
      <p>Rm 1003 10/F LIPPO CTR TWR 1 Admiralty HK</p>
      <p>jvjvnee@gmail.com</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.title{
  color: black;
  margin-top: 100px;
}
.des{
  margin-top: 100px;
  width: 600px;
  text-align: center;
}
.container{
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  background:linear-gradient(180deg, #FFF1CC 0%, #FFF 100%)
}
.addr{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.addr p {
  text-align: left; /* 段落内文本左对齐 */
}
</style>
